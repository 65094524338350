import Link from "next/link";
import { useI18n } from "../../hooks/use-i18n";
import Logo from "../Logo";
import background from "./geschenk.png";

export default (props) => {
  const i18n = useI18n();

  return (
    <div id="featured-area" className="promo-active">
      <div className="sideslides">
        <div className="bxslider">
          <div className="slide-item">
            <div
              className="feat-item"
              style={{ backgroundImage: `url(${background})` }}
            ></div>
            <div className="feat-overlay">
              <Logo compact={true} />

              <h1>
                <Link href="/">
                  <a>bitte.kaufen</a>
                </Link>
                &nbsp;
                <p>{i18n.t("web.landingPage.featuredArea.title")}</p>
              </h1>
              <div className="feat-read-more">
                <Link href={i18n.t("web.urls.wishlistsIndex")}>
                  <a className="read-more">
                    {i18n.t("web.common.createWishlist")}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
