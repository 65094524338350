import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { useRouter } from "next/router";
import AlertFactory from "../components/Alert/factory";
import Benefits from "../components/Benefits";
import Explanation from "../components/Explanation";
import FeaturedArea from "../components/FeaturedArea";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import { useI18n, withI18nRedirect } from "../hooks/use-i18n";
import "./index.less";

const free =
  "/images/benefits/kostenlos-wunschzettel-erstellen-bitte-kaufen.jpg";
const forEveryone =
  "/images/benefits/online-wunschzettel-anlegen-bitte-kaufen.jpg";
const withoutRegistration =
  "/images/benefits/online-wunschzettel-erstellen-bitte-kaufen.jpg";
const simple =
  "/images/benefits/einfach-wunschliste-erstellen-bitte-kaufen.jpg";
const nativeApps = "/images/benefits/mein-wunschzettel-app-bitte-kaufen.jpg";

const preGridContent = ({
  i18n,
  renderLogoutSuccessAlert,
  renderActivationFailedAlert,
}) => (
  <>
    <FeaturedArea />
    {renderLogoutSuccessAlert && AlertFactory("logout-success", i18n)}
    {renderActivationFailedAlert && AlertFactory("activation-failed", i18n)}
    <Benefits />
  </>
);

export default function Index() {
  const { query } = useRouter();
  const i18n = useI18n();
  const renderLogoutSuccessAlert = query.alert === "logout-success";
  const renderActivationFailedAlert = query.alert === "activation-failed";

  return (
    <Layout
      className="root-page"
      preGridContent={() =>
        preGridContent({
          i18n,
          renderLogoutSuccessAlert,
          renderActivationFailedAlert,
        })
      }
      hideMobileLogo={true}
    >
      <div id="main">
        <Explanation
          id="vorteil-native-app"
          imageUrl={nativeApps}
          title={i18n.t("web.landingPage.explanations.nativeApp.title")}
          body={i18n.t("web.landingPage.explanations.nativeApp.body")}
          links={[
            {
              text: "iOS",
              to: "https://apps.apple.com/de/app/bitte-kaufen/id1550997406",
              icon: faApple,
            },
            {
              text: "Android",
              to: "https://play.google.com/store/apps/details?id=com.depold.bittekaufen&hl=de&gl=DE",
              icon: faAndroid,
            },
          ]}
        />

        <Explanation
          id="vorteil-kostenlos"
          imageUrl={free}
          title={i18n.t("web.landingPage.explanations.free.title")}
          body={i18n.t("web.landingPage.explanations.free.body")}
        />

        <Explanation
          id="vorteil-leicht-verstaendlich"
          imageUrl={forEveryone}
          title={i18n.t("web.landingPage.explanations.withoutRegistration.title")}
          body={i18n.t("web.landingPage.explanations.withoutRegistration.body")}
        />

        <Explanation
          id="vorteil-fuer-jeden-anlass"
          imageUrl={withoutRegistration}
          title={i18n.t("web.landingPage.explanations.forEveryone.title")}
          body={i18n.t("web.landingPage.explanations.forEveryone.body")}
        />

        <Explanation
          id="vorteil-intuitiv-einfach"
          imageUrl={simple}
          title={i18n.t("web.landingPage.explanations.easyToUse.title")}
          body={i18n.t("web.landingPage.explanations.easyToUse.body")}
        />
        <div className="explanations-compact">
          <Explanation
            title={i18n.t("web.landingPage.explanations.summary.title")}
            body={i18n.t("web.landingPage.explanations.summary.body")}
            wishlistButton={false}
          />

          <Explanation
            title={i18n.t("web.landingPage.explanations.forGrandParents.title")}
            body={i18n.t("web.landingPage.explanations.forGrandParents.body")}
            wishlistButton={false}
            headlineLevel={3}
          />

          <Explanation
            title={i18n.t("web.landingPage.explanations.forEveryOccasion.title")}
            body={i18n.t("web.landingPage.explanations.forEveryOccasion.body")}
            headlineLevel={3}
          />
        </div>
      </div>
      <Sidebar withLatestPosts={true} />
    </Layout>
  );
}

export async function getServerSideProps({ req, res }) {
  return withI18nRedirect(req, res) || { props: {} };
}
