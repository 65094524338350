import "./style.less";
import classNames from "classnames";
import { useI18n } from "../../hooks/use-i18n";

export default (props) => {
  const i18n = useI18n();

  const benefits = [
    {
      backgroundImage:
        "/images/benefits/kostenlos-wunschzettel-erstellen-bitte-kaufen.jpg",
      title: i18n.t("web.landingPage.benefits.free"),
      link: "/#vorteil-kostenlos",
    },
    {
      backgroundImage:
        "/images/benefits/online-wunschzettel-anlegen-bitte-kaufen.jpg",
      title: i18n.t("web.landingPage.benefits.withoutRegistration"),
      link: "/#vorteil-leicht-verstaendlich",
    },
    {
      backgroundImage:
        "/images/benefits/online-wunschzettel-erstellen-bitte-kaufen.jpg",
      title: i18n.t("web.landingPage.benefits.easyToUse"),
      link: "/#vorteil-fuer-jeden-anlass",
    },
    {
      backgroundImage:
        "/images/benefits/mein-wunschzettel-app-bitte-kaufen.jpg",
      title: i18n.t("web.landingPage.benefits.ourApp"),
      link: "/magazin/wunschzettel-app-ios-android/",
      containerClass: "promo-item-align-top",
    },
  ];

  return (
    <div id="promo-area" className="benefits">
      <div className="feat-line"></div>

      <div className="sp-container">
        <div className="sp-row">
          {benefits.map((benefit, index) => (
            <div className="sp-col-3" key={index}>
              <div
                className={classNames("promo-item", benefit.containerClass)}
                style={{ backgroundImage: `url(${benefit.backgroundImage})` }}
              >
                <a href={benefit.link} className="promo-link"></a>
                <div className="promo-overlay">
                  <h4>{benefit.title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
