import "./style.less";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18n } from "../../hooks/use-i18n";

export default (props) => {
  const i18n = useI18n();

  return (
    <div className="sp-col-12" id={props.id}>
      <article className="explanation list-item post type-post status-publish format-gallery has-post-thumbnail hentry category-travels post_format-post-format-gallery">
        {props.imageUrl && (
          <div className="post-img">
            <a href={props.href}>
              <img
                width="520"
                height="400"
                src={props.imageUrl}
                className="attachment-laurel-misc-thumb size-laurel-misc-thumb wp-post-image"
                alt=""
              />
            </a>
          </div>
        )}

        <div className="list-content">
          <div className="post-header ">
            {props.headlineLevel === 3 ? (
              <h3>
                <a href={props.href}>{props.title}</a>
              </h3>
            ) : (
              <h2>
                <a href={props.href}>{props.title}</a>
              </h2>
            )}
          </div>

          <div className="post-entry is-excerpt">
            {[].concat(props.body).map((body, i) => (
              <p key={i}>{body}</p>
            ))}
          </div>

          {props.wishlistButton !== false && !props.links && (
            <Link href={i18n.t('web.urls.wishlistsIndex')}>
              <a className="read-more">{i18n.t("web.common.createWishlist")}</a>
            </Link>
          )}

          {props.links?.map((link, i) => (
            <Link href={link.to} key={i}>
              <a className="read-more read-more-explanation-pill">
                {link.icon && (
                  <FontAwesomeIcon icon={link.icon} className="icon" />
                )}
                {link.text}
              </a>
            </Link>
          ))}
        </div>
      </article>
    </div>
  );
};
